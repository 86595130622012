.navbarr {
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.497);
    padding: 16px;
    border-radius: 7px;
    margin-top: 22px;
    margin: auto;
    width: 10%;
    padding: 10px;
}

.headerr {
    border: 5px solid rgba(86, 86, 86, 0.376);
}

.Link {
    text-decoration: none;
    color: black;
    font-weight: 800;
    font-size: 20px;
}

.Link:hover {
    color: rgb(200, 0, 0);
}

.btnn {
    margin-right: 4px;
    /* border: 5px solid rgb(144, 3, 3); */
    border-radius: 10px;
    background-color: rgb(255, 228, 228);
}

.top {
    /* background: rgba(255, 255, 255, 0.1); */
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.497);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    padding: 16px;
    border-radius: 7px;
    margin-top: 15px;
}

.toop {
    /* background: rgba(255, 255, 255, 0.1); */
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.497);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    padding: 16px;
    border-radius: 7px;
    margin-top: 15px;
    transition: all 0.3s linear;
}

.midd {
    margin: auto;
    width: 45%;
    padding: 10px;
}

label {
    font-size: 15px;
    /* color: white; */
    color: black;
}

iframe {
    width: 100%;
    height: 295px;
}

textarea {
    height: 100px;
}

#btn {
    padding: 9px;
    margin-top: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.497);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    border: none;
    /* color: black; */
    color: white;
    font-size: 20px;
}

span {
    /* color: black; */
    color: rgb(0, 33, 131);
    text-shadow: -1px -1px 10px rgb(255, 255, 255);
}

.output {
    margin-top: 30px;
    padding: 5px;
    color: black;
    background-color: white;
    justify-content: center;
    display: block;
}

.error {
    display: flex;
    font-size: 13px;
}

.table {
    margin-top: 20px;
}

th,
td {
    padding: 10px;
}

tr:nth-child(even) {
    background-color: #d6eeee;
}

.Tmidd {
    margin: auto;
    width: 100%;
    padding: 10px;
}

.HomeBTN {
    text-align: center;
    margin: auto;
    width: 50%;
    margin-top: 30px;
}

.homeBTN {
    margin-left: 14px;
}